<template>
  <v-dialog persistent max-width="500" v-model="showDialog">
    <v-card>
      <v-toolbar dark flat>
        <v-toolbar-title>Orderbon-Druck</v-toolbar-title>
        <v-card-subtitle class="white--text">{{ this.$t("generic.lang_errorOccurred") }}</v-card-subtitle>
      </v-toolbar>

      <v-card-text style="padding: 0;">
        <v-list style="padding-top: 0;">
          <v-list-item v-for="(rejectedPrint, index) in rejectedPrintResults" :key="index">
            <v-list-item-content>{{ $t("settings.langOrderbonPrinter") }}
              {{ rejectedPrint.reason.payload.targetPrinter.address }}
              (ID {{ rejectedPrint.reason.payload.targetPrinter.id }}) {{ $t("generic.lang_isNotAvailable") }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>

      <v-card-actions>
        <v-btn color="error" @click="cancelReprint" :disabled="reprintLoading" text>{{
            this.$t("generic.lang_cancel")
          }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="reprintRejectedPromises" :disabled="reprintLoading || disableReprint"
               :loading="reprintLoading">{{ this.$t("generic.lang_print_again") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.v-list .v-list-item:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}
</style>

<script>
import {mapGetters, mapState} from "vuex";
import {Events} from "@/plugins/events";
import {ENDPOINTS} from "@/config";
import {createOrderBonPrintingData} from "@/plugins/printing/printOrderBon";
import {createVoidOrderBonPrintingData} from "@/plugins/printing/printVoidOrderBon";
import moment from "moment";
import {printDataFromPrinter} from "../../plugins/printing/printerController";
import printInterimBill from "@/mixins/pos/buttons/printInterimBill";
import {createInterimInvoicePrintingData} from "@/plugins/printing/printInvoice";

export default {
  name: "PrintOrderbon",
  data() {
    return {
      printResults: [],
      reprintLoading: false,
      disableReprint: false,
      estimatedAsapTime: null,
    }
  },

  watch: {
    showDialog(v) {
      this.disableReprint = false;
    }
  },

  computed: {
    ...mapState([
      'api',
      'pos',
      'settings',
      'posLayoutTemplates',
      'user'
    ]),
    ...mapState('printer', {
      printers: state => state.printers
    }),
    ...mapState('pos/gastro', {
      table: state => state.table
    }),
    ...mapGetters({
      activeUser: 'multiUser/activeUser',
      delivery: 'pos/gastro/getDelivery',
      deliveryInfo: 'pos/gastro/getDeliveryInfo',
      itemsTotalPrice: 'pos/gastro/totalPrice',
      totalWithoutGFV: 'pos/gastro/totalWithoutGFV',
      openItemsTotalPrice: 'pos/gastro/openItemsTotalPrice',
      deliveryPayment: 'pos/gastro/getDeliveryPayment',
      canOrderParty: 'pos/gastro/canOrderParty',
      serviceTime: 'pos/gastro/getServiceTime',
      forcedVOR: 'pos/gastro/forcedVOR',
    }),
    rejectedPrintResults() {
      return this.printResults.filter((printResult) => printResult.status === "rejected");
    },
    showDialog() {
      return this.rejectedPrintResults.length > 0;
    },
    multiUser() {
      if (this.settings.settings.hasOwnProperty("posGastroLayoutTemplate")) {
        //FIND TEMPLATE
        let templateID = 0;
        templateID = parseInt(this.settings.settings.posGastroLayoutTemplate);

        if (templateID > 0) {
          return this.posLayoutTemplates.templates.some(template => {
            if (templateID === template.id) {
              return (template.multiUser === 1 || template.multiUser === true)
            }
          });
        }
      }
      return false;
    },
    realUser() {
      if (this.multiUser)
        if (this.activeUser.userID !== null)
          return this.activeUser;

      return this.user;
    },
    invoicePrinter() {
      return this.printers.find((printer) => {
        if (printer.cashierID.includes(this.api.auth.cashierID)) {
          if (printer.type === 1) {
            return true;
          }
        }
      });
    },
    shouldPrintOrderInterimReceipt(){
      return (Number(this.settings.settings.printInterimOrderReceipt) && this.pos.gastro.table.name != 0) && this.invoicePrinter
    }
  },

  methods: {
    cancelReprint() {
      this.$emit("cancelReprint");

      this.printResults = [];
    },
    async getPickupName() {
      await this.axios.post(ENDPOINTS.POS.GASTRO.PICKUPNAMES).then((res) => {
        if (res.data.status) {
          this.$store.dispatch('pos/gastro/setPickup', res.data.pickUpNumber + '')
        }
      }).catch(err => {
        console.log(err);
      })
    },
      /**
       * This is also responsible for printing order interim receipt
       */
    printOrderBons() {
      return new Promise((resolve, reject) => {
        const partyPromises = [];
        //CHECK IF WE NEED TO GET ALL PARTIES
        if (this.table.name !== 0) {
          // CHECK DELIVERY SERVICE
          if (this.pos.gastro.isDeliveryService) {
            // USING ONLY ACTIVE PARTY
            partyPromises.push(this.orderPartyItems(this.table, this.pos.gastro.party));
          } else {
            for (let party of this.table.parties) {
              if (this.canOrderParty(party.name)) {
                partyPromises.push(this.orderPartyItems(this.table, {name: party.name}));
              }
            }
          }
        } else {
          //TRESENVERKAUF -> ONLY ACTIVE PARTY
          partyPromises.push(this.orderPartyItems(this.table, this.pos.gastro.party));
        }
        //WAIT FOR ALL PROMISES FINISHING
        Promise.allSettled(partyPromises).then((results) => {
          this.$store.commit("pos/gastro/setFreetext", null);

          //CHECK IF PROMISE HAS ERROR
          let promiseError = results.find((result) => result.status === "rejected");

          if (promiseError)
            reject(promiseError);
          else
            resolve();
        }).catch((err) => {
          console.log(err)
          reject(err);
          this.$store.commit("pos/gastro/setFreetext", null);
        });
      });
    },
    async orderPartyItems(table, party) {
      this.loading = true;

      //CHECK STACKING ITEMS
      let orderedItems = [];
      let openItems = [];

      // CHECK THE AIR BEFORE SETTING THE PICK NUMBER
      if (table.name !== 0 && this.pos.gastro.isDeliveryService && !this.pos.gastro.pickup) {
        await this.getPickupName();
      }


      //GET OPEN ITEMS
      if (this.pos.gastro.openItems.hasOwnProperty(party.name))
        openItems = this._.cloneDeep(this.pos.gastro.openItems[party.name]);

      //CHECK IF NOT TRESENVERKAUF

      if (table.name !== 0) {

        if (this.pos.gastro.orderedItems.hasOwnProperty(party.name)) {
          orderedItems = this._.cloneDeep(this.pos.gastro.orderedItems[party.name]);

          //CHECK IF PARTY EXISTS IN OPEN ITEMS
          if (this.pos.gastro.openItems.hasOwnProperty(party.name)) {
            //LOOP ALL OPENITEMS FROM PARTY
            openItems.forEach((item) => {
              let openItem = this._.cloneDeep(item);
              openItem.needsVoidPrint = false;

              //GET ORDERED ITEMS WITH THE FILTERS
              const orderedItem = orderedItems.find(orderedItem => {
                if (orderedItem.id === openItem.id) {
                  if (orderedItem.name === openItem.name) {
                    if (orderedItem.originalSellPrice === orderedItem.sellPrice) {
                      if (orderedItem.sellPrice === openItem.sellPrice) {
                        if (orderedItem.discount === openItem.discount) {
                          if (!orderedItem.isVoid) {
                            if (!openItem.course) {
                              if (orderedItem.selectedExtras.length === 0 && openItem.selectedExtras.length === 0) {
                                if (orderedItem.mealSizeID === openItem.mealSizeID) {
                                  return true;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }

                return false;
              });

              if (orderedItem) {
                orderedItem.amount += openItem.amount;
              } else {
                orderedItems.push(openItem);
              }
            });
          }
        } else {
          orderedItems = openItems;
        }
      } else {
        orderedItems = openItems;
      }

      return new Promise((resolve, reject) => {
        let endpoint = ENDPOINTS.POS.GASTRO.ORDER.CREATE
        let tableName = (table !== null ? table.name : 0);
        let deliveryTime = 0;
        let serviceTime = null;
        let FORM = {
          tableName: tableName,
          partyName: party.name,
          items: orderedItems,
          openItems: openItems,
          isTakeAway: this.$store.state.pos.gastro.takeAway === 1,
          isAsap: false,
          pagerNumber: this.pos.gastro.pagerNo,
        };
        let isScheduled = RegExp(/^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2})$/).test(this.serviceTime);
        let serviceTimeDateFormat = isScheduled ? "YYYY-MM-DD HH:mm" : "HH:mm";

        if (tableName === 0) {
          FORM.pickupNumber = this.pos.gastro.pickup
        }

        if (this.$store.getters['pos/gastro/isFromReservation']) {
          FORM.reservation_uuid = this.$store.getters['pos/gastro/reservationUUID'];
        }

        FORM.orderFreetext = this.pos.gastro.freeText;

        if (this.pos.gastro.customer) {
          FORM.customerId = this.pos.gastro.customer.id
        }

        if (this.pos.gastro.isDeliveryService) {
          let preparationTime = 0;
          endpoint = ENDPOINTS.DELIVERY.ORDER.CREATE;
          FORM.isDelivery = this.pos.gastro.delivery ? 1 : 0;
          FORM.orderFreetext = this.pos.gastro.freeText;
          FORM.paymentType = this.deliveryPayment;
          FORM.pickupNumber = this.pos.gastro.pickup;

          if (this.$route.query.hasOwnProperty('useDeliveryService') && this.$route.query.useDeliveryService) {
            FORM.uuid = this.$route.query.useDeliveryService;
          }

          //setting the service time based on preparation time + (if its delivery) the delivery time

          if (this.delivery && this.deliveryInfo) {
            FORM.deliveryAreaID = this.deliveryInfo.deliveryAreaID;

            if (this.deliveryInfo.carDeliveryTime === null) {
              this.deliveryInfo.carDeliveryTime = 0;
            }
            if (this.deliveryInfo.motorcycleDeliveryTime === null) {
              this.deliveryInfo.motorcycleDeliveryTime = 0;
            }

            deliveryTime = this.deliveryInfo.carDeliveryTime > this.deliveryInfo.motorcycleDeliveryTime ? this.deliveryInfo.carDeliveryTime : this.deliveryInfo.motorcycleDeliveryTime
            preparationTime = Number(this.settings.settings.lb_deliveryPreparationTime) ? Number(this.settings.settings.lb_deliveryPreparationTime) : 20;
          } else {
            FORM.deliveryAreaID = 0;
            preparationTime = Number(this.settings.settings.lb_pickUpPreparationTime) ? Number(this.settings.settings.lb_pickUpPreparationTime) : 20;
            deliveryTime = 0;
          }

          FORM.serviceTime = moment(this.serviceTime, serviceTimeDateFormat).isValid() ? this.serviceTime : moment().add(preparationTime + deliveryTime, 'minutes').format(serviceTimeDateFormat);

          serviceTime = moment(this.serviceTime, serviceTimeDateFormat).isValid() ?
              moment(this.serviceTime, serviceTimeDateFormat).add(-Number(deliveryTime), 'minutes').format(serviceTimeDateFormat)
              : moment().add(preparationTime, 'minutes').format(serviceTimeDateFormat);
          this.estimatedAsapTime = moment().add(preparationTime, 'minutes').format(serviceTimeDateFormat);

          if (moment(serviceTime, serviceTimeDateFormat).isSameOrBefore(moment(this.estimatedAsapTime, serviceTimeDateFormat))) {
            FORM.isAsap = true;
          }

          if (this.forcedVOR) {
            FORM.isAsap = false;
          }
        }

        if (this.pos.gastro.isDeliveryService && this.$route.query.hasOwnProperty('useDeliveryService')) {
          if (this.delivery) {
            if (!((this.totalWithoutGFV - Number(this.deliveryInfo.deliveryCost)) >= Number(this.deliveryInfo.minOrder))) {
              Events.$emit("showSnackbar", {
                message: this.$t('delivery.lang_unreached_order_price'),
                color: "warning"
              });
              resolve();
              return;
            }
          }
        }


        return this.axios.post(endpoint, FORM).then(async (res) => {
          if (res.data.success || (!res.data.success && res.data.type === "warning")) {
            //SKIP IF OPENITEMS ARE EMPTY (ONLY WHEN WE ARE SAVING)
            /*
             if(openItems.length === 0)
                 return resolve();
             */


            // APPLYING CASHIER OPTIONS SETTINGS FOR DIRECT SALE ORDERBON PRINTING
            if (this.settings.settings.disableOrderbonPrintingDirectSale === "1" && (this.pos.gastro.table.hasOwnProperty('name') && this.pos.gastro.table.name === 0)) {
              return resolve();
            }

            //GET ALL ORDERBON PRINTERS
            let targetPrinters = {};
            let dupTargetPrinters = {};
            if (this.$route.query.hasOwnProperty('useDeliveryService') && !this.$route.query.useDeliveryService) {
              await this.$router.replace({query: {useDeliveryService: res.data.orderID}})
            }

            if(this.shouldPrintOrderInterimReceipt){
              targetPrinters[this.invoicePrinter.id+'_'+'invoice'] = this.invoicePrinter;
              targetPrinters[this.invoicePrinter.id+'_'+'invoice']['items'] = [];
            }

            if (openItems)
                // !!!
                // USE FOR-LOOP
                // because forEach is not promise-aware. It cannot support async and await. You cannot use await in forEach.
                // !!!
              for (let openItem in openItems) {
                if (!openItems.hasOwnProperty(openItem))
                  continue;

                openItem = openItems[openItem];

                let targetPrinter = await this.getOrderBonPrinter(openItem);
                let dupTargetPrinter = await this.getDupOrderBonPrinter(openItem);

                let itemTranslation = null;
                if (Number(this.settings.settings.enableOrderbonTranslations)) {
                  itemTranslation = await this.getItemOrderTranslationByID(openItem.id);
                }


                if(this.shouldPrintOrderInterimReceipt){
                  targetPrinters[this.invoicePrinter.id+'_'+'invoice']['items'].push(openItem);
                }

                if (targetPrinter !== null) {
                  //CHECK IF PRINTER ALREADY ADDED TO LIST
                  if (!targetPrinters.hasOwnProperty(targetPrinter.id)) {
                    //ADD EMPTY ARRAY
                    targetPrinter.items = [];

                    targetPrinters[targetPrinter.id] = targetPrinter;
                  }
                  targetPrinters[targetPrinter.id].items.push(openItem);

                  if (Number(this.settings.settings.enableOrderbonTranslations) && itemTranslation) {
                    targetPrinters[targetPrinter.id].items[targetPrinters[targetPrinter.id].items.length - 1].itemOrderingNameTranslationCanvas = itemTranslation.canvas;
                    targetPrinters[targetPrinter.id].items[targetPrinters[targetPrinter.id].items.length - 1].itemOrderingNameTranslation = itemTranslation.text;
                  }

                  if(targetPrinters[targetPrinter.id]?.options?.orderbon_printAllPositions && !targetPrinters[targetPrinter.id]?.options?.accompaniedItems){
                    targetPrinters[targetPrinter.id].options.accompaniedItems = openItems;
                  }
                }
                //CHECK FOR DUP ITEMGROUP ORDER BON PRINTER

                if (dupTargetPrinter !== null) {
                  //CHECK IF PRINTER ALREADY ADDED TO LIST
                  if (!dupTargetPrinters.hasOwnProperty(dupTargetPrinter.id)) {
                    //ADD EMPTY ARRAY
                    dupTargetPrinter.items = [];

                    dupTargetPrinters[dupTargetPrinter.id] = dupTargetPrinter;
                  }

                  dupTargetPrinters[dupTargetPrinter.id].items.push(openItem);

                  if (Number(this.settings.settings.enableOrderbonTranslations) && itemTranslation) {
                    dupTargetPrinters[dupTargetPrinter.id].items[dupTargetPrinters[dupTargetPrinter.id].items.length - 1].itemOrderingNameTranslationCanvas = itemTranslation.canvas;
                    dupTargetPrinters[dupTargetPrinter.id].items[dupTargetPrinters[dupTargetPrinter.id].items.length - 1].itemOrderingNameTranslation = itemTranslation.text;
                  }

                  if(dupTargetPrinters[dupTargetPrinter.id]?.options?.orderbon_printAllPositions && !dupTargetPrinters[dupTargetPrinter.id]?.options?.accompaniedItems){
                    dupTargetPrinters[dupTargetPrinter.id].options.accompaniedItems = openItems;
                  }
                }
              }

            //CHECK IF NEED TO PRINT VOIDED ITEMS
            //THEN GET ALL TARGET PRINTERS FOR VOIDED ITEMS
            let voidedItems = orderedItems.filter((orderedItem) => orderedItem.isVoid);
            let alreadyPrintedVoidedItems = [];
            if (this.pos.gastro.voidedItems.hasOwnProperty(party.name)) {
              alreadyPrintedVoidedItems = this.pos.gastro.voidedItems[party.name];
            }
            let voidedItemsToPrint = voidedItems.filter(item => !alreadyPrintedVoidedItems.includes(item.randomPositionID))
            let targetPrintersVoid = {};
            let dupTargetPrintersVoid = {};

            if (voidedItemsToPrint.length > 0) {
              for (let openVoidItem in voidedItemsToPrint) {

                openVoidItem = voidedItemsToPrint[openVoidItem];
                let targetPrinter = await this.getOrderBonPrinter(openVoidItem);
                let dupTargetPrinter = await this.getDupOrderBonPrinter(openVoidItem);

                let itemTranslation = null;
                if (Number(this.settings.settings.enableOrderbonTranslations)) {
                  itemTranslation = await this.getItemOrderTranslationByID(openVoidItem.id);
                }

                if (targetPrinter !== null) {
                  //CHECK IF PRINTER ALREADY ADDED TO LIST
                  if (!targetPrintersVoid.hasOwnProperty(targetPrinter.id)) {
                    //ADD EMPTY ARRAY
                    targetPrinter.items = [];
                    targetPrintersVoid[targetPrinter.id] = targetPrinter;
                  }

                  targetPrintersVoid[targetPrinter.id].items.push(openVoidItem);
                  if (Number(this.settings.settings.enableOrderbonTranslations) && itemTranslation) {
                    targetPrintersVoid[targetPrinter.id].items[targetPrintersVoid[targetPrinter.id].items.length - 1].itemOrderingNameTranslationCanvas = itemTranslation.canvas;
                    targetPrintersVoid[targetPrinter.id].items[targetPrintersVoid[targetPrinter.id].items.length - 1].itemOrderingNameTranslation = itemTranslation.text;
                  }
                }
                //CHECK FOR DUP ITEMGROUP ORDER BON PRINTER
                if (dupTargetPrinter !== null) {
                  //CHECK IF PRINTER ALREADY ADDED TO LIST
                  if (!dupTargetPrintersVoid.hasOwnProperty(dupTargetPrinter.id)) {
                    //ADD EMPTY ARRAY
                    dupTargetPrinter.items = [];

                    dupTargetPrintersVoid[dupTargetPrinter.id] = dupTargetPrinter;
                  }

                  dupTargetPrintersVoid[dupTargetPrinter.id].items.push(openVoidItem);
                  if (Number(this.settings.settings.enableOrderbonTranslations) && itemTranslation) {
                    dupTargetPrintersVoid[dupTargetPrinter.id].items[dupTargetPrintersVoid[dupTargetPrinter.id].items.length - 1].itemOrderingNameTranslationCanvas = itemTranslation.canvas;
                    dupTargetPrintersVoid[dupTargetPrinter.id].items[dupTargetPrintersVoid[dupTargetPrinter.id].items.length - 1].itemOrderingNameTranslation = itemTranslation.text;
                  }
                }
              }
              // set voided items already printed;
              this.$store.commit("pos/gastro/setVoidedItems", {
                partyName: party.name,
                items: voidedItems.map(item => item.randomPositionID)
              });
            }

            //SKIP PRINTING WHEN NO TARGET PRINTERS ARE AVAILABLE
            /*
            if (Object.keys(targetPrinters).length === 0)
                return resolve();
             */

            //CREATE ARRAY WITH PROMISES
            const printPromises = [];

            for (let targetPrinter in targetPrinters) {
              if (!targetPrinters.hasOwnProperty(targetPrinter))
                continue;
              if(targetPrinter.includes('invoice')){
                printPromises.push(this.interimOrderReceiptPromise(res, party, table, targetPrinters[targetPrinter]));
              }else {
                //PUSH PROMISE TO QUEUE
                printPromises.push(this.orderbonPrintPromise(res, party, table, targetPrinters[targetPrinter]));
              }
            }

            //LOOP TARGET PRINTERS VOID
            for (let targetPrinterVoid in targetPrintersVoid) {
              if (!targetPrintersVoid.hasOwnProperty(targetPrinterVoid))
                continue;

              //PUSH PROMISE TO QUEUE
              printPromises.push(this.orderbonPrintPromise(res, party, table, targetPrintersVoid[targetPrinterVoid], true));
            }

            // LOOPING TROUGH THE DUPLICATE ORDERBON PRINTER //

            for (let targetPrinter in dupTargetPrinters) {
              if (!dupTargetPrinters.hasOwnProperty(targetPrinter))
                continue;

              // PUSH PROMISE TO QUEUE
              printPromises.push(this.orderbonPrintPromise(res, party, table, dupTargetPrinters[targetPrinter], false, true));
            }

            //LOOP TARGET PRINTERS VOID
            for (let targetPrinterVoid in dupTargetPrintersVoid) {
              if (!dupTargetPrintersVoid.hasOwnProperty(targetPrinterVoid))
                continue;

              //PUSH PROMISE TO QUEUE
              printPromises.push(this.orderbonPrintPromise(res, party, table, dupTargetPrintersVoid[targetPrinterVoid], true, true));
            }

            //WAIT FOR ALL PROMISES FINISHING
            Promise.allSettled(printPromises).then((results) => {
              //APPEND PRINT RESULTS
              this.printResults = this.printResults.concat(results);

              //CHECK IF PROMISE HAS ERROR
              let promiseError = results.find((result) => result.status === "rejected");

              if (promiseError)
                reject(promiseError);
              else
                resolve();
            }).catch((err) => {

              console.log(err);
              reject(err);
            }).finally(() => {
              //CLEAR CASHIER
              if (table.name !== 0) {
                //ORDER ITEMS
                this.$store.commit("pos/gastro/setOrderedItems", {
                  partyName: party.name,
                  orders: orderedItems
                });

                this.$store.commit("pos/gastro/orderItems", {
                  party: party
                });
              }

              //EMIT SOCKET EVENT FOR KITCHENMONITOR
              // WE'RE USING THE SAME EVENT FOR TABLEBEE
              this.$socket.emit("pos.createOrder", {
                table: {
                  uuid: this.pos.gastro.table && this.pos.gastro.table.uuid,//this.pos.gastro.table.uuid
                  name: tableName,
                  party: party.name,
                },
                items: orderedItems
              });
              Events.$emit('createOrder')
            });
          } else if (!res.data.success && res.data.type === "error") {
            this.$swal({
              title: this.$t('generic.lang_errorOccurred'),
              text: res.data.msg,
              confirmButtonText: this.$t('generic.lang_prev'),
              icon: "error",
              showLoaderOnConfirm: true,
              preConfirm: () => {
                reject()
              },
              allowOutsideClick: () => !this.$swal.isLoading()
            });
          }
        });
      });
    },
    async getOrderBonPrinter(item) {
      // FIRST GET ITEM
      let originalItem = await this.$store.dispatch("items/getItemByID", item.id);

      if (!originalItem)
        return null;

      let itemOrderbonprinterID = originalItem.orderbonPrinterID;

      // GET ITEMGROUP
      let itemgroupOrderbonprinterID = null;

      let itemgroup = await this.$store.dispatch("itemgroups/getItemgroupByID", originalItem.itemgroupID);

      if (itemgroup)
        itemgroupOrderbonprinterID = itemgroup.orderbonPrinterID;

      // --------------------------------------------------
      //CHECK ORDERBON PRINTER ON ITEM
      if (itemOrderbonprinterID > 0) {
        let orderbonPrinter = this.printers.find((printer) => Number(printer.id) === Number(itemOrderbonprinterID));
        if (orderbonPrinter) {
          //CHECK IF VIRTUAL PRINTER
          if (orderbonPrinter.type === 4) {
            if (orderbonPrinter.virtualOrderbonPrinterAssignment) {
              if (orderbonPrinter.virtualOrderbonPrinterAssignment.hasOwnProperty('rooms')) {
                let roomPrinter = null;
                // CHECK IF DIRECT SALE OR TABLE
                if (this.table && this.table.name !== 0) {

                  roomPrinter = orderbonPrinter.virtualOrderbonPrinterAssignment.rooms.find(room => room.id === this.pos.gastro.lastRoom.id)
                  if (roomPrinter) {
                    orderbonPrinter = this.printers.find((printer) => Number(printer.id) === Number(roomPrinter.printer_id));
                  } else {
                    //going back to default if room doesn't have a assigned printer
                    roomPrinter = orderbonPrinter.virtualOrderbonPrinterAssignment.rooms.find(room => room.id === 'default')
                    if (roomPrinter) {
                      orderbonPrinter = this.printers.find((printer) => Number(printer.id) === Number(roomPrinter.printer_id))
                    }
                  }
                } else {
                  // SEARCHING FOR THE DEFAULT PRINTER FOR DIRECT SALE
                  roomPrinter = orderbonPrinter.virtualOrderbonPrinterAssignment.rooms.find(room => room.id === 'default')
                  if (roomPrinter) {
                    orderbonPrinter = this.printers.find((printer) => Number(printer.id) === Number(roomPrinter.printer_id));
                  }
                }

                if (orderbonPrinter && orderbonPrinter.type !== 4)
                  return Object.assign({}, orderbonPrinter);

              } else if (orderbonPrinter.virtualOrderbonPrinterAssignment.hasOwnProperty('cashiers')) {
                let cashierPrinter = null;

                cashierPrinter = orderbonPrinter.virtualOrderbonPrinterAssignment.cashiers.find(cashier => cashier.id === this.api.auth.cashierID)
                if (cashierPrinter) {
                  orderbonPrinter = this.printers.find((printer) => Number(printer.id) === Number(cashierPrinter.printer_id));
                } else {
                  cashierPrinter = orderbonPrinter.virtualOrderbonPrinterAssignment.cashiers.find(cashier => cashier.id === 'default')
                  if (cashierPrinter) {
                    orderbonPrinter = this.printers.find((printer) => Number(printer.id) === Number(cashierPrinter.printer_id))
                  }
                }

                if (orderbonPrinter && orderbonPrinter.type !== 4)
                  return Object.assign({}, orderbonPrinter);
              }
            }
          } else {
            return Object.assign({}, orderbonPrinter);
          }
        }

      }

      //CHECK ORDERBON PRINTER ON ITEMGROUP
      if (!itemgroup)
        return null;
      if (itemgroupOrderbonprinterID > 0) {
        let orderbonPrinter = this.printers.find((printer) => Number(printer.id) === Number(itemgroupOrderbonprinterID));

        if (orderbonPrinter) {
          //CHECK IF VIRTUAL PRINTER
          if (orderbonPrinter.type === 4) {
            if (orderbonPrinter.virtualOrderbonPrinterAssignment) {
              if (orderbonPrinter.virtualOrderbonPrinterAssignment.hasOwnProperty('rooms')) {
                let roomPrinter = null;
                // CHECK IF DIRECT SALE OR TABLE
                if (this.table && this.table.name !== 0) {
                  roomPrinter = orderbonPrinter.virtualOrderbonPrinterAssignment.rooms.find(room => room.id === this.pos.gastro.lastRoom.id)
                  if (roomPrinter) {
                    orderbonPrinter = this.printers.find((printer) => Number(printer.id) === Number(roomPrinter.printer_id));
                  } else {
                    //going back to default if room doesn't have a assigned printer
                    roomPrinter = orderbonPrinter.virtualOrderbonPrinterAssignment.rooms.find(room => room.id === 'default')
                    if (roomPrinter) {
                      orderbonPrinter = this.printers.find((printer) => Number(printer.id) === Number(roomPrinter.printer_id))
                    }
                  }
                } else {
                  // SEARCHING FOR THE DEFAULT PRINTER FOR DIRECT SALE
                  roomPrinter = orderbonPrinter.virtualOrderbonPrinterAssignment.rooms.find(room => room.id === 'default')
                  if (roomPrinter) {
                    orderbonPrinter = this.printers.find((printer) => Number(printer.id) === Number(roomPrinter.printer_id));
                  }
                }

                if (orderbonPrinter && orderbonPrinter.type !== 4)
                  return Object.assign({}, orderbonPrinter);

              } else if (orderbonPrinter.virtualOrderbonPrinterAssignment.hasOwnProperty('cashiers')) {
                let cashierPrinter = null;

                cashierPrinter = orderbonPrinter.virtualOrderbonPrinterAssignment.cashiers.find(cashier => cashier.id === this.api.auth.cashierID)
                if (cashierPrinter) {
                  orderbonPrinter = this.printers.find((printer) => Number(printer.id) === Number(cashierPrinter.printer_id));
                } else {
                  cashierPrinter = orderbonPrinter.virtualOrderbonPrinterAssignment.cashiers.find(cashier => cashier.id === 'default')
                  if (cashierPrinter) {
                    orderbonPrinter = this.printers.find((printer) => Number(printer.id) === Number(cashierPrinter.printer_id))
                  }
                }

                if (orderbonPrinter && orderbonPrinter.type !== 4)
                  return Object.assign({}, orderbonPrinter);
              }
            }
          } else {
            return Object.assign({}, orderbonPrinter);
          }
        }
        //NO PRINTER FOUND
        return null;
      } else if (itemgroupOrderbonprinterID === 0) {
        // FIND MAIN PRINTER OF THIS CASHIER ID
        let orderbonPrinter = this.printers.find((printer) => printer.type === 1 && printer.cashierID.includes(this.api.auth.cashierID));

        if (orderbonPrinter)
            //CLONE OBJECT
          return Object.assign({}, orderbonPrinter);
      }

      return null;
    },

    async getDupOrderBonPrinter(item) {
      // FIRST GET ITEM
      let originalItem = await this.$store.dispatch("items/getItemByID", item.id);

      if (!originalItem)
        return null;

      // GET ITEMGROUP
      let itemgroupDupOrderbonprinterID = null;

      let itemgroup = await this.$store.dispatch("itemgroups/getItemgroupByID", originalItem.itemgroupID);

      if (itemgroup)
        itemgroupDupOrderbonprinterID = itemgroup.duplicate_orderbonPrinterID;

      //CHECK DUP ORDERBON PRINTER ON ITEMGROUP
      if (!itemgroup)
        return null;

      if (itemgroupDupOrderbonprinterID > 0) {
        let orderbonPrinter = this.printers.find((printer) => printer.id === itemgroupDupOrderbonprinterID);

        if (orderbonPrinter)
            //CLONE OBJECT
          return Object.assign({}, orderbonPrinter);

        //NO PRINTER FOUND
        return null;
      }

      return null;
    },
    interimOrderReceiptPromise(axiosRes, party, table, targetPrinter){
      return new Promise((resolve, reject) => {
        //GENERATE INVOICE DATA
        let data = {
          cashierID: this.api.auth.cashierID,
          userID: axiosRes.data.userID,
          ts: axiosRes.data.timestamp || Math.floor(Date.now()/1000),
          tableNo: (table !== null ? table.name : "Tresenverkauf"),
          party: party.name,
          paymentType: 1,
          isAsap: false,
          goodsValue: this.openItemsTotalPrice,
          vouchersValue: 0,
          moneyGiven: 0,
          customer: this.pos.gastro.customer
        };
        let serviceTime = null;

        if (this.pos.gastro.isDeliveryService) {
          let deliveryTime = 0;
          let preparationTime = 0;
          let isScheduled = RegExp(/^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2})$/).test(this.serviceTime);
          let serviceTimeDateFormat = isScheduled ? "YYYY-MM-DD HH:mm" : "HH:mm";
          data.isDelivery = this.delivery;
          if (this.delivery) {
            if (this.deliveryInfo.carDeliveryTime === null) {
              this.deliveryInfo.carDeliveryTime = 0;
            }

            deliveryTime = this.deliveryInfo.carDeliveryTime > this.deliveryInfo.motorcycleDeliveryTime ? this.deliveryInfo.carDeliveryTime : this.deliveryInfo.motorcycleDeliveryTime
            data.deliveryTime = deliveryTime;
            preparationTime = Number(this.settings.settings.lb_deliveryPreparationTime) ? Number(this.settings.settings.lb_deliveryPreparationTime) : 20;

            //add zip code
            data.zip = this.deliveryInfo?.zipCode;

          } else {
            preparationTime = Number(this.settings.settings.lb_pickUpPreparationTime) ? Number(this.settings.settings.lb_pickUpPreparationTime) : 20;
          }

          serviceTime = moment(this.serviceTime, serviceTimeDateFormat).isValid() ?
              moment(this.serviceTime, serviceTimeDateFormat).add(-Number(deliveryTime), 'minutes').format(serviceTimeDateFormat)
              : moment().add(preparationTime, 'minutes').format(serviceTimeDateFormat);
          this.estimatedAsapTime = moment().add(preparationTime, 'minutes').format(serviceTimeDateFormat);

          if (moment(serviceTime, serviceTimeDateFormat).isSameOrBefore(moment(this.estimatedAsapTime, serviceTimeDateFormat))) {
            data.isAsap = true;
          }

          if (this.forcedVOR) {
            data.isAsap = false;
          }

          data.serviceTime = serviceTime;
        }

        //GET XML PRINTING DATA
        let printData;
        try {
            printData = createInterimInvoicePrintingData(targetPrinter.items, data, targetPrinter.options, this.realUser);
        } catch (err) {
          console.log(err)
          reject({
            error: err,
            payload: {
              axiosRes: axiosRes,
              party: party,
              table: table,
              targetPrinter: targetPrinter
            }
          });
        }

        //CHECK DEVICE ID
        let deviceID;

        if (typeof targetPrinter.deviceID === 'undefined' || targetPrinter.deviceID.length < 2) {
          deviceID = "local_printer"
        } else {
          deviceID = targetPrinter.deviceID;
        }

        printDataFromPrinter(targetPrinter, printData, deviceID).then(() => {
          resolve();
        }).catch((err) => {
          console.log(err);
          reject({
            error: err,
            payload: {
              axiosRes: axiosRes,
              party: party,
              table: table,
              targetPrinter: targetPrinter
            }
          });
        }).finally(() => {
          this.loadingPrinting = false;
        })
      });
    },
    orderbonPrintPromise(axiosRes, party, table, targetPrinter, isVoidPrint = false, isDup = false) {

      //RETURN PROMISE
      return new Promise((resolve, reject) => {
        //GENERATE INVOICE DATA
        let data = {
          userID: axiosRes.data.userID,
          timestamp: axiosRes.data.timestamp,
          table: (table !== null ? table.name : "Tresenverkauf"),
          party: party.name,
          isAsap: false,
        };
        let serviceTime = null;
        let duplicatePrintCount = 1;

        if (this.pos.gastro.isDeliveryService) {
          let deliveryTime = 0;
          let preparationTime = 0;
          let isScheduled = RegExp(/^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2})$/).test(this.serviceTime);
          let serviceTimeDateFormat = isScheduled ? "YYYY-MM-DD HH:mm" : "HH:mm";
          data.isDelivery = this.delivery;
          if (this.delivery) {
            if (this.deliveryInfo.carDeliveryTime === null) {
              this.deliveryInfo.carDeliveryTime = 0;
            }

            deliveryTime = this.deliveryInfo.carDeliveryTime > this.deliveryInfo.motorcycleDeliveryTime ? this.deliveryInfo.carDeliveryTime : this.deliveryInfo.motorcycleDeliveryTime
            data.deliveryTime = deliveryTime;
            preparationTime = Number(this.settings.settings.lb_deliveryPreparationTime) ? Number(this.settings.settings.lb_deliveryPreparationTime) : 20;

            //add zip code
            data.zip = this.deliveryInfo?.zipCode;

          } else {
            preparationTime = Number(this.settings.settings.lb_pickUpPreparationTime) ? Number(this.settings.settings.lb_pickUpPreparationTime) : 20;
          }

          serviceTime = moment(this.serviceTime, serviceTimeDateFormat).isValid() ?
              moment(this.serviceTime, serviceTimeDateFormat).add(-Number(deliveryTime), 'minutes').format(serviceTimeDateFormat)
              : moment().add(preparationTime, 'minutes').format(serviceTimeDateFormat);
          this.estimatedAsapTime = moment().add(preparationTime, 'minutes').format(serviceTimeDateFormat);

          if (moment(serviceTime, serviceTimeDateFormat).isSameOrBefore(moment(this.estimatedAsapTime, serviceTimeDateFormat))) {
            data.isAsap = true;
          }

          if (this.forcedVOR) {
            data.isAsap = false;
          }

          data.serviceTime = serviceTime;
        }

        if (Number(this.$store.getters['settings/getSettingValue']('pickupQRCodeActive')) === 1) {
          data.orderUUID = axiosRes.data.kitchenMonID;
        }

        //GET XML PRINTING DATA
        let printData;
        try {
          if (!isVoidPrint)
            printData = createOrderBonPrintingData(targetPrinter.items, data, targetPrinter.options, this.pos.gastro.freeText, this.realUser, false, isDup);
          else
            printData = createVoidOrderBonPrintingData(targetPrinter.items, data, targetPrinter.options, this.pos.gastro.freeText, this.realUser, isDup);
        } catch (err) {

          console.log(err)
          reject({
            error: err,
            payload: {
              axiosRes: axiosRes,
              party: party,
              table: table,
              targetPrinter: targetPrinter
            }
          });
        }

        //CHECK DEVICE ID
        let deviceID;

        if (typeof targetPrinter.deviceID === 'undefined' || targetPrinter.deviceID.length < 2) {
          deviceID = "local_printer"
        } else {
          deviceID = targetPrinter.deviceID;
        }


        // CHECK HOW MANY TIMES WE NEED TO PRINT
        if (targetPrinter.options.hasOwnProperty("duplicateOrderBonPrint")) {
          if (Number(targetPrinter.options.duplicateOrderBonPrint) > 0) {
            duplicatePrintCount = Number(targetPrinter.options.duplicateOrderBonPrint);
          }
        }

        // adding the duplicate printing loop based on the target printer options
        let i = 0;
        do {
          //PRINT XML
          printDataFromPrinter(targetPrinter, printData, deviceID).then(() => {
            resolve();
          }).catch((err) => {
            reject({
              error: err,
              payload: {
                axiosRes: axiosRes,
                party: party,
                table: table,
                targetPrinter: targetPrinter
              }
            });
          }).finally(() => {
            this.loadingPrinting = false;
          })
          ++i;
        } while (i < duplicatePrintCount);
      });
    },
    reprintRejectedPromises() {
      this.reprintLoading = true;
      this.disableReprint = true;

      //CREATE ARRAY WITH PROMISES
      const printPromises = [];

      this.rejectedPrintResults.forEach((rejectedPrintResult) => {
        printPromises.push(this.orderbonPrintPromise(rejectedPrintResult.reason.payload.axiosRes, rejectedPrintResult.reason.payload.party, rejectedPrintResult.reason.payload.table, rejectedPrintResult.reason.payload.targetPrinter,))
      });

      //WAIT FOR ALL PROMISES FINISHING
      Promise.allSettled(printPromises).then((results) => {
        this.printResults = results;
        this.reprintLoading = false;

        //CHECK IF PROMISE HAS ERROR
        let promiseError = results.find((result) => result.status === "rejected");

        if (!promiseError) {
          this.$emit("reprintFinished");
        }
      }).catch(() => {
        this.$emit("reprintFinished");
      });
    },
    async getItemOrderTranslationByID(id) {
      let item = await this.$store.dispatch('items/getItemByID', id)
      if (item) {
        return {
          canvas: item.itemOrderingNameTranslationCanvas,
          text: item.itemOrderingNameTranslation,
        }
      }
      return null;
    },

  }
}
</script>
